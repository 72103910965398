import { ReactNode, useEffect, useRef, useState } from 'react';
import ScrollProvider from '../Providers/ScrollProvider';
import { PhotocardFloatingBtn } from './PhotocardFloatingBtn/PhotocardFloatingBtn';
import '@/styles/index.scss';

export const PhotocardListLayout = ({ children }: { children: ReactNode }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isScrolledFromTop, setIsScrolledFromTop] = useState(false);

  const handleScroll = () => {
    const scrollPosition = contentRef.current?.scrollTop ?? 0;
    setIsScrolledFromTop(scrollPosition > 0);
  };

  const scrollToTop = () => {
    contentRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const currentContentRef = contentRef.current;
    currentContentRef?.addEventListener('scroll', handleScroll);
    return () => currentContentRef?.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ScrollProvider contentRef={contentRef}>
      <div ref={contentRef} className="scroll-provider-wrapper">
        {children}
        {isScrolledFromTop && <PhotocardFloatingBtn moveToTop={scrollToTop} />}
      </div>
    </ScrollProvider>
  );
};
