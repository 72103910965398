import { WebviewAction } from '@/utils/action';
import toQuerystring from '@/utils/to-querystring';
import { track } from '@vercel/analytics';
import { isEmpty } from 'lodash';

export interface IResponse<T> {
  success: boolean;
  code: string;
  data: T;
  detail?: string;
}

export interface IRequestParams<T> extends RequestInit {
  url?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: T;
  token?: string;
}

export const ENDPOINT = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

export const defaultHeaders = {
  Accept: '*/*',
  'Accept-Encoding': 'gzip, deflate, br',
  Connection: 'keep-alive',
  'Content-Type': 'application/json'
};

export async function request<T, K>({
  url,
  method,
  params,
  token,
  ...options
}: {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: T;
  token?: string;
} & RequestInit) {
  try {
    options.headers = {
      ...defaultHeaders,
      ...options.headers,
      ...(token
        ? {
            Authorization: `Bearer ${token}`
          }
        : {})
    };

    let endPoint = `${ENDPOINT}${url}`;
    let data;
    if (method !== 'GET' && params) data = params;
    else if (method === 'GET' && params && !isEmpty(params)) endPoint = `${ENDPOINT}${url}?${toQuerystring(params)}`;

    const res = await fetch(endPoint, {
      method,
      headers: options.headers,
      ...(data && { body: JSON.stringify(data) }),
      ...options
    });

    const fetchData = await res.json();

    if (!res.ok) throw fetchData;

    return fetchData.data as K;
  } catch (error: any) {
    const deviceId = WebviewAction() ?? 'kioskdev';
    track(url, {
      device_id: deviceId,
      method: method ?? '',
      error_message: error.message ?? error.detail,
      error_code: error.code
    });
    console.error('There was a SyntaxError', error);

    // eslint-disable-next-line no-throw-literal, @typescript-eslint/no-throw-literal
    throw error as IResponse<K>;
  }
}
