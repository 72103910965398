const DEFAULT_FILTER = 'all';

/**
 * @description
 * 카테고리 파라미터를 설정하거나 제거
 * 모든 타입이 기본 필터와 같거나, 타입 배열이 비어있으면 카테고리를 제거
 * 그렇지 않으면, 타입 배열을 ,로 연결하여 카테고리를 설정
 */
const clearOrSetCategoryParams = (params: URLSearchParams, type: string[]) => {
  if (type.every((t) => t === DEFAULT_FILTER) || type.length === 0) {
    params.delete('category');
    return;
  }
  params.set('category', type.join(','));
};

/**
 * @description
 * 주어진 검색 파라미터를 바탕으로 새로운 URLSearchParams 객체를 생성,
 * 가격과 타입에 따라 적절한 파라미터를 설정하거나 제거한 후, 문자열로 반환.
 */
export const updateQueryParams = (searchParams: any, type: string[]) => {
  const params = new URLSearchParams(searchParams.toString());

  clearOrSetCategoryParams(params, type);

  return params.toString();
};
