import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getArtistGroups } from '@/apis/main';
import { ArtistGroup } from '@/types/photocard';
import { PhotocardsContext } from '@/routes/photocard/_components/Provider';
import './index.scss';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

export function GroupFilter() {
  const { group, setGroup } = useContext(PhotocardsContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: artistGroups } = useQuery({
    queryFn: () => getArtistGroups(),
    queryKey: ['group-list'],
    select: (data) => data.groups
  });

  const onClick = (grp: ArtistGroup) => {
    setGroup(grp);
    searchParams.set('group', String(grp.id));
    searchParams.delete('member');
    searchParams.delete('q');
    navigate(`${location.pathname}?${searchParams}`);
  };

  return (
    <div className="photocard-list-page-artist-list-container">
      {artistGroups?.map((artist: ArtistGroup) => (
        <div
          key={`${artist.name_en}`}
          className={`photocard-list-page-artist-item-container${
            artist.name_en === group.name_en ? ' photocard-list-page-artist-item-container-selected' : ''
          }`}
          onClick={() => onClick(artist)}
        >
          <img src={artist.image} alt="artist logo" className="photocard-list-page-artist-item-image" />
          <span
            className={`photocard-list-page-artist-item-text${
              artist.name_en === group.name_en ? ' photocard-list-page-artist-item-text-selected' : ''
            }`}
          >
            {artist.name_en}
          </span>
        </div>
      ))}
    </div>
  );
}
