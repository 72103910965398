import useApi from './useApi';
import { useMutation } from '@tanstack/react-query';
import { removeFromCart } from '@/apis/main';

export const useReset = () => {
  const { fetchApi } = useApi();

  const deleteCartMutation = useMutation({
    mutationFn: (offerList: number[]) => fetchApi((accessToken) => removeFromCart(offerList, accessToken))
  });

  const resetCart = () => {
    deleteCartMutation.mutate([]);
  };

  const goHome = () => {
    window.location.href = '/';
  };

  return { resetCart, goHome };
};

export default useReset;
