import { daysArr } from '@/utils/daysArr';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// 픽업 문구 케이스
// 1. Today 7:00 PM ~ following 3 days
// 2. Tomorrow 12:00 PM ~ following 3 days
// 3. Next Monday 12:00 PM~ following 3 days

export const PickUpNotice = () => {
  const daysNumber = moment().day();
  const days = daysArr[daysNumber];
  const [pickUpTextCase, setPickTextCase] = useState<{ before: number; after: number }>({ before: 1, after: 2 });

  useEffect(() => {
    if (days !== '토' && days !== '일') {
      setPickTextCase({ before: 1, after: days === '금' ? 3 : 2 });
    } else {
      setPickTextCase({ before: 3, after: 3 });
    }
  }, []);

  return (
    <div className="order-page-section">
      <div className="order-page-section-agreement-title">
        <FormattedMessage id="pick_up_notice_title" />
      </div>
      <div>
        <div className="pick-up-notice-sub-title">
          <FormattedMessage id="pick_up_notice_sub_title_1" />
        </div>
        <ul className="pick-up-notice-ul">
          <li>
            <FormattedMessage id="pick_up_notice_sub_title_1_list_1" />
          </li>
          <li>
            <FormattedMessage id="pick_up_notice_sub_title_1_list_2" />
          </li>
        </ul>
      </div>
      <div>
        <div className="pick-up-notice-sub-title">
          <FormattedMessage id="pick_up_notice_sub_title_2" />
        </div>
        <div className="pick-up-notice-sub-title-desc">
          <FormattedMessage id="pick_up_notice_sub_desc_1" />
        </div>

        <div className="pick-up-table-wrapper">
          <table border={1} className="pick-up-table">
            <thead>
              <tr className="pick-up-table_head">
                <td className="pick-up-table_first" />
                <td>
                  <FormattedMessage id="pick_up_table_head_text_1" />
                </td>
                <td>
                  <FormattedMessage id="pick_up_table_head_text_2" />
                </td>
              </tr>
            </thead>
            <tr className="pick-up-table_contents">
              <td className="pick-up-table_first">
                <FormattedMessage id={`pick_up_table_days_${daysNumber}`} />
              </td>
              <td>
                <FormattedMessage id={`pick_up_table_case_text_${pickUpTextCase?.before}`} />
              </td>
              <td>
                <FormattedMessage id={`pick_up_table_case_text_${pickUpTextCase?.after}`} />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};
