import InfiniteCarousel from '@/components/InfiniteCarousel/InfiniteCarousel';
import './PromotionCarousel.scss';

const promotionImgsArr = ['STEP-1', 'STEP-2', 'STEP-3', 'STEP-4'];
const nArr = ['-50px', '-250px', '-300px', '-190px', '-315px', '-200px'];

const PromotionCarousel = () => {
  return (
    <div className="promotion-carousel-wrapper">
      {nArr.map((topValue, idx) => (
        <InfiniteCarousel
          key={topValue}
          sectionNum={idx + 1}
          Imgs={promotionImgsArr}
          topValue={topValue}
          isReverse={idx % 2 === 1}
        />
      ))}
    </div>
  );
};

export default PromotionCarousel;
