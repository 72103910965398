import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './SortModal.scss';
import { SORT_LIST } from '@/routes/photocard/_utils/data';

interface IProps {
  onClose: () => void;
}

const SortModal = ({ onClose }: IProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const sortValue = searchParams.get('sort') ?? 'new';

  function handleChangeRadio(sortItem: string) {
    searchParams.set('sort', sortItem === 'Newest' ? 'new' : 'wish');
    navigate(`${location.pathname}?${searchParams}`);
    onClose();
  }

  return (
    <div className="sort-modal-wrapper" onClick={(e) => e.stopPropagation()}>
      {Object.keys(SORT_LIST).map((idx) => {
        return (
          <span
            key={idx}
            className={`${SORT_LIST[Number(idx)].params === sortValue ? 'sort-modal-item-select' : 'sort-modal-item'}`}
            onClick={() => handleChangeRadio(SORT_LIST[Number(idx)].name)}
          >
            <FormattedMessage id={`sort_text_${Number(idx) + 1}`} />
          </span>
        );
      })}
    </div>
  );
};

export default SortModal;
