import { ICartAPI, ICheckAPI, ICheckPurchaseAPI } from '@/types/checkout';
import { request } from './request';

export const getCartOffers = (accessToken: string) =>
  request<ICartAPI.Request, ICartAPI.Response>({
    url: `/collection/kiosk/v1/cart?res_type=cart_id`,
    method: 'GET',
    token: accessToken
  });

export const postCheckPurchase = (accessToken: string, data: ICheckPurchaseAPI.Request) =>
  request<ICheckPurchaseAPI.Request, ICheckPurchaseAPI.Response>({
    url: `/collection/kiosk/v1/check-purchase`,
    method: 'POST',
    token: accessToken,
    params: data
  });

export const postPurchase = (accessToken: string, data: ICheckAPI.Request) =>
  request<ICheckAPI.Request, ICheckAPI.Response>({
    url: `/collection/kiosk/v1/purchase`,
    method: 'POST',
    token: accessToken,
    params: data
  });
