import './PhotocardFloatingBtn.scss';

interface IProps {
  moveToTop: () => void;
}

export const PhotocardFloatingBtn = ({ moveToTop }: IProps) => {
  return (
    <button className="photocard-floating-btn-wrapper" onClick={moveToTop}>
      <img src="/icons/arrow-top.svg" alt="arrow-top" />
    </button>
  );
};
