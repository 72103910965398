import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './MemberSearch.scss';
import { KeyboardEventHandler, useEffect, useState } from 'react';
import useI18nText from '@/hooks/useI18nText';

const MemberSearch = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const paramsSearchValue = searchParams.get('q') ?? '';
  const [searchValue, setSearchValue] = useState(paramsSearchValue);

  const handleResetSearch = () => {
    setSearchValue('');
    searchParams.delete('q');
    navigate(`${location.pathname}?${searchParams}`);
  };

  const handleSubmit = () => {
    searchParams.set('q', searchValue);
    navigate(`${location.pathname}?${searchParams}`);
  };

  const handleClickSearch: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') handleSubmit();
  };

  useEffect(() => setSearchValue(paramsSearchValue), [paramsSearchValue]);

  return (
    <>
      <div className="member-search-wrapper">
        <img src="/icons/search.svg" alt="search-svg" />
        <input
          className="member-search-input"
          type="text"
          value={searchValue}
          placeholder={useI18nText('search')}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleClickSearch}
        />
        {searchValue && <img src="/icons/huge.svg" alt="huge-svg" onClick={handleResetSearch} />}
      </div>
    </>
  );
};

export default MemberSearch;
