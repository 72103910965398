import { IRequestParams, request } from './request';
import {
  AddToCartAPI,
  GetArtistGroupsAPI,
  GetArtistMembersAPI,
  GetCartAPI,
  GetCartOffersAPI,
  GetPhotocardsAPI,
  RemoveFromCartAPI
} from '@/types/main';

export const getArtistGroups = () =>
  request<GetArtistGroupsAPI.Request, GetArtistGroupsAPI.Response>({
    url: `/card/kiosk/v1/group`,
    method: 'GET'
  });

export const getCartOffers = (accessToken?: string) =>
  request<GetCartOffersAPI.Request, GetCartOffersAPI.Response>({
    url: `/collection/kiosk/v1/cart`,
    method: 'GET',
    token: accessToken
  });

export const getArtistMembers = (groupId: number) =>
  request<GetArtistMembersAPI.Request, GetArtistMembersAPI.Response>({
    url: `/card/gb/v1/group/${groupId}/member`,
    method: 'GET'
  });

export const getPhotocards = (options: IRequestParams<GetPhotocardsAPI.Request>) =>
  request<GetPhotocardsAPI.Request, GetPhotocardsAPI.Response>({
    url: `/card/kiosk/v1/search`,
    method: 'GET',
    ...options
  });

export const getCart = (accessToken?: string) =>
  request<GetCartAPI.Request, GetCartAPI.Response>({
    url: `/collection/kiosk/v1/cart`,
    method: 'GET',
    token: accessToken
  });

export const addToCart = (offerId: number, accessToken?: string) =>
  request<AddToCartAPI.Request, AddToCartAPI.Response>({
    url: `/collection/kiosk/v1/cart`,
    method: 'POST',
    token: accessToken,
    params: { offer_id: offerId }
  });

export const removeFromCart = (offerList: number[], accessToken?: string) =>
  request<RemoveFromCartAPI.Request, RemoveFromCartAPI.Response>({
    url: `/collection/kiosk/v1/cart`,
    method: 'DELETE',
    token: accessToken,
    params: { offer_list: offerList }
  });

export const getCountries = (accessToken?: string) =>
  request<GetCartAPI.Request, GetCartAPI.Response>({
    url: `/collection/gb/v1/shipping/countries`,
    method: 'GET',
    token: accessToken
  });

export const deleteCache = (accessToken?: string) =>
  request({
    url: `/payment/kiosk/v1/clear-cache`,
    method: 'POST',
    token: accessToken
  });
