export const TYPE_LIST = [
  { name: 'all', title: 'All Types' },
  { name: '1', title: 'Album' },
  { name: '9', title: 'Benefit' },
  { name: '7', title: 'MD' },
  { name: '8', title: 'Collabo' },
  { name: '6', title: 'Concert' },
  { name: '2', title: `Season's Greeting` },
  { name: '4', title: 'Fan Meeting' },
  { name: '5', title: 'Fan Club' },
  { name: '3', title: 'Fan Sign' },
  { name: '10', title: 'Etc' }
];

export const SORT_LIST = [
  {
    name: 'Newest',
    params: 'new'
  },
  {
    name: 'Favorite',
    params: 'wish'
  }
];
