import Button from '@/components/Button';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';
import { FormattedMessage } from 'react-intl';
import SkipButton from '../_components/SkipButton';
import LocaleSelection from '@/components/LocaleSelection/LocaleSelection';

const PromotionPage = () => {
  const { direct } = useWebViewRouter();

  return (
    <>
      <div className="home-container">
        <img src="/images/bi.png" alt="bi" className="home-logo" />
        <div className="home-talk-bubble">
          <div className="triangle" />
          <span className="home-talk-bubble-text">
            <FormattedMessage id="onboarding_speechBubble" />
          </span>
        </div>
        <img src="/character/onboard.png" alt="character" className="home-character" />
        <span className="home-description-text">
          <FormattedMessage id="onboarding_description1" />
          <br />
          <FormattedMessage id="onboarding_description2" />
        </span>
        <div className="home-onboarding-buttons-wrapper">
          <Button onClick={() => direct('/onboarding')}>
            <FormattedMessage id="onboarding_button" />
          </Button>
          <SkipButton />
        </div>
        <LocaleSelection />
      </div>
    </>
  );
};

export default PromotionPage;
