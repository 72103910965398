import '@/styles/photocard/list.scss';
import CartModal from '@/components/CartModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeFromCart } from '@/apis/main';
import useApi from '@/hooks/useApi';
import useLocalStorage from '@/hooks/useLocalStorage';
import useToggle from '@/hooks/useToggle';
import CartDeleteModal from '@/components/CartDeleteModal';
import { PhotocardsProvider } from './_components/Provider';
import { GNB } from '@/components/GNB';
import { GroupFilter } from '@/components/GroupFilter';
import MemberFilter from '@/components/MemberFilter/MemberFilter';
import { CardsList } from '@/components/CardsList';
import { CartButton } from '@/components/CartButton';
import { FormattedMessage } from 'react-intl';
import { PickUpBanner } from '@/components/PickUpBanner/PickUpBanner';
import { useCart, useTimer } from '@/hooks';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';
import FilterBar from './_components/FilterBar/FilterBar';
import MemberSearch from './_components/MemberSearch/MemberSearch';
import { PhotocardListLayout } from './_components/PhotocardListLayout';
import { useSearchParams } from 'react-router-dom';

const PhotocardListPage = () => {
  const [searchParams] = useSearchParams();
  const { direct } = useWebViewRouter();
  const { fetchApi } = useApi();
  const [currency] = useLocalStorage('currency', null);
  const { ref } = useTimer();
  const { cartData } = useCart();
  const queryClient = useQueryClient();

  // toggle
  const companyModal = useToggle({ showing: false });
  const sortModal = useToggle({ showing: false });
  const cartModal = useToggle({ showing: false });
  const typeSectionModal = useToggle({ showing: false });
  const emptyCartModal = useToggle({ showing: false });
  const deleteModal = useToggle({ showing: false });

  const category = searchParams.get('category');
  const sort = searchParams.get('sort') ?? 'new';
  const group = searchParams.get('group') ?? '5';
  const member = searchParams.get('member') ?? '0';
  const q = searchParams.get('q') ?? '';

  const deleteCartMutation = useMutation({
    mutationFn: (offerList: number[]) => fetchApi((accessToken) => removeFromCart(offerList, accessToken)),
    mutationKey: ['cart'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart'] });
      queryClient.invalidateQueries({ queryKey: ['card-list', group, member, category, sort, q] });
    }
  });

  return (
    <PhotocardListLayout>
      <PhotocardsProvider>
        <div
          ref={ref}
          onClick={() => {
            companyModal.isShowing && companyModal.close();
            sortModal.isShowing && sortModal.close();
          }}
        >
          <GNB cartData={cartData} companyModal={companyModal} />
          <GroupFilter />
          <div className="photocard-list-page-main">
            <div className="photocard-list-page-filter-section-wrapper">
              <MemberSearch />
              <MemberFilter />
              <FilterBar sortModal={sortModal} typeSectionModal={typeSectionModal} />
            </div>
            <CardsList onClick={() => typeSectionModal.isShowing && typeSectionModal.close()} />
            <div className="photocard-list-bottom-area">
              <PickUpBanner />
              <CartButton emptyTrigger={emptyCartModal} cartTrigger={cartModal} />
            </div>
            {emptyCartModal.isShowing && (
              <div className="empty-cart-modal-wrapper">
                <FormattedMessage id="card-modal_empty" />
              </div>
            )}
          </div>
        </div>

        {cartModal.isShowing && (
          <CartModal
            isSoloArtist={false}
            currency={currency}
            items={cartData ?? []}
            onClose={() => cartModal.close()}
            onItemDelete={(item) => deleteCartMutation.mutate([item.offer.id])}
            onAllItemDelete={() => deleteModal.open()}
            onSubmit={() => {
              if (cartData && cartData.length > 0) {
                direct('/order');
              } else {
                cartModal.close();
                emptyCartModal.open();
                setTimeout(() => {
                  emptyCartModal.close();
                }, 800);
              }
            }}
          />
        )}
        {deleteModal.isShowing && (
          <CartDeleteModal
            onClick={() => {
              deleteCartMutation.mutate([]);
              deleteModal.close();
            }}
            onClose={() => deleteModal.close()}
          />
        )}
      </PhotocardsProvider>
    </PhotocardListLayout>
  );
};

export default PhotocardListPage;
