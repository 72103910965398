import { IssueAccessTokenAPI, RefreshAccessTokenAPI } from '@/types/auth';
import { defaultHeaders, ENDPOINT, IRequestParams } from './request';
import { track } from '@vercel/analytics';
import { WebviewAction } from '@/utils/action';

export const issueAccessToken = (options: IRequestParams<IssueAccessTokenAPI.Request>) =>
  fetch(`${ENDPOINT}/token/kiosk`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(options.params),
    ...options
  })
    .then(async (res) => {
      const data = await res.json();
      if (!res.ok) throw data;
      return data;
    })
    .catch((error) => {
      const deviceId = WebviewAction() ?? 'kioskdev';
      track('/token/kiosk', { device_id: deviceId, error_message: error.message, error_code: error.code });
    }) as Promise<IssueAccessTokenAPI.Response>;

export const refreshAccessToken = (options: IRequestParams<RefreshAccessTokenAPI.Request>) =>
  fetch(`${ENDPOINT}/accounts/token/refresh/`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(options.params),
    ...options
  })
    .then(async (res) => {
      const data = await res.json();
      if (!res.ok) throw data;
      return data;
    })
    .catch((error) => {
      const deviceId = WebviewAction() ?? 'kioskdev';
      track('/accounts/token/refresh/', { device_id: deviceId, error_message: error.message, error_code: error.code });
    }) as Promise<RefreshAccessTokenAPI.Response>;
