import { getCart } from '@/apis/main';
import { useQuery } from '@tanstack/react-query';
import useApi from './useApi';

export const useCart = () => {
  const { fetchApi } = useApi();
  const { data: cartData } = useQuery({
    queryFn: () => fetchApi((accessToken) => getCart(accessToken)),
    queryKey: ['cart'],
    select: (data) => data.cart_offers
  });

  return { cartData };
};
