import useLocalStorage from '@/hooks/useLocalStorage';
import { getPrice } from '@/utils/getPrice';
import { FormattedMessage } from 'react-intl';

export default function CartResultSection({ cartDataLength, subtotal }: { cartDataLength: number; subtotal: number }) {
  const [currency] = useLocalStorage('currency', null);

  return (
    <div className="order-page-cart-result-area">
      <div className="order-page-cart-result-item">
        <span className="order-page-cart-result-item-text">
          <FormattedMessage id="order_cart_quantity" />:
        </span>
        <span className="order-page-cart-result-item-text">
          {cartDataLength} <FormattedMessage id="order_cart_items" />
        </span>
      </div>
      <div className="order-page-cart-result-item">
        <span className="order-page-cart-result-item-total-text">
          <FormattedMessage id="order_cart_total" />
        </span>
        <span className="order-page-cart-result-item-total-text">{`${getPrice(Number(subtotal).toString(), currency)}`}</span>
      </div>
    </div>
  );
}
