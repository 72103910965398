import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { TYPE_LIST } from '../../_utils/data';
import './TypeSection.scss';
import { useUpdateTypeSelection } from '@/hooks/useUpdateTypeSelection';
import { FormattedMessage } from 'react-intl';

export default function TypeSection() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateTypeSelection } = useUpdateTypeSelection();
  const type = searchParams.get('category')?.split(',') ?? ['all'];

  const handleClick = (name: string) => {
    updateTypeSelection(name, type);
  };

  const handleResetClick = () => {
    searchParams.delete('category');
    navigate(`${location.pathname}?${searchParams}`);
  };

  return (
    <section className="type-section-wrapper">
      <div className="type-section-title">
        <span className="type-section-title__type">
          <FormattedMessage id={`type`} />
        </span>
        <span className="type-section-title__reset" onClick={handleResetClick}>
          <img src="/icons/reset.svg" alt="reset-svg" />
          <FormattedMessage id={`reset`} />
        </span>
      </div>

      <div className="type-section-list">
        {TYPE_LIST.map((data) => (
          <div
            className={`type-section-item ${type.includes(data.name) ? 'item-selected' : 'item-not-selected'}`}
            key={`type_${data.name}`}
            onClick={() => handleClick(data.name)}
          >
            <p className="text-title-4">
              <FormattedMessage id={`filter_name_${data.name}`} />
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
