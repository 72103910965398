import { useQuery } from '@tanstack/react-query';
import './Filter.scss';
import { getArtistMembers } from '@/apis/main';
import { useContext, useEffect, useRef } from 'react';
import { PhotocardsContext } from '@/routes/photocard/_components/Provider';
import { ArtistMember } from '@/types/photocard';
import { Swiper as SwiperCore } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper/modules';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const allMember = [{ id: 0, name: 'All', name_en: 'All', image: '' }];

const MemberFilter = () => {
  const { group, member, setMember } = useContext(PhotocardsContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const swiperRef = useRef<SwiperCore>();

  const { data } = useQuery({
    queryFn: () => getArtistMembers(group.id ?? 5),
    queryKey: ['member-list', group.id ?? 5],
    select: (data) => data.members
  });
  const artistMembers = data && ([...allMember, ...data] as ArtistMember[]);

  const onClick = (clickedMember: ArtistMember) => {
    swiperRef.current?.slideTo(swiperRef.current?.clickedIndex - 4);
    const targetArtistMember = artistMembers?.find((item) => item === clickedMember);
    if (targetArtistMember) {
      setMember(targetArtistMember);
      searchParams.set('member', String(targetArtistMember.id));
      searchParams.delete('q');
      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  useEffect(() => {
    artistMembers && setMember(artistMembers[0].name !== '태일' ? artistMembers[0] : artistMembers[1]);
  }, [data]);

  return (
    <Swiper
      onBeforeInit={(swiper) => (swiperRef.current = swiper)}
      slidesPerView="auto"
      spaceBetween={12}
      modules={[FreeMode]}
      freeMode={{
        sticky: true,
        momentum: true,
        momentumRatio: 2,
        momentumVelocityRatio: 0.3
      }}
      className="filter-container photocard-list-page-artist-filter card-swiper-wrapper"
    >
      {artistMembers?.map(
        (artist) =>
          artist.name !== '태일' && (
            <SwiperSlide
              key={artist.name_en}
              className={`filter-item-container${member?.name_en === artist.name_en ? ' filter-item-container-selected' : ''}`}
              onClick={() => onClick(artist)}
            >
              <span className={`filter-item-text${member?.name_en === artist.name_en ? ' filter-item-text-selected' : ''}`}>
                {artist.name_en}
              </span>
            </SwiperSlide>
          )
      )}
    </Swiper>
  );
};

export default MemberFilter;
