import { ReactNode, RefObject, createContext, useContext } from 'react';

interface IProps {
  children: ReactNode;
  contentRef: RefObject<HTMLDivElement>;
}

const ScrollContext = createContext<RefObject<HTMLDivElement> | null>(null);

export default function ScrollProvider({ children, contentRef }: IProps) {
  return <ScrollContext.Provider value={contentRef}>{children}</ScrollContext.Provider>;
}

export function useScrollContext() {
  return useContext(ScrollContext);
}
