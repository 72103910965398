import { cartDatas } from '@/types/main';
import BackModal from '@/components/BackModal';
import CompanyModal from '@/components/CompanyModal';
import { useReset } from '@/hooks';
import useToggle, { IToggleTrigger } from '@/hooks/useToggle';
import { PhotocardsContext } from '@/routes/photocard/_components/Provider';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import LocaleSelectionSimple from '../LocaleSelectionSimple/LocaleSelectionSimple';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';

interface IProps {
  cartData: cartDatas[] | undefined;
  companyModal: IToggleTrigger;
}

export function GNB({ cartData, companyModal }: IProps) {
  const backModal = useToggle({ showing: false });
  const { resetCart, goHome } = useReset();
  const { group } = useContext(PhotocardsContext);
  const { direct } = useWebViewRouter();

  return (
    <div className="photocard-gnb-wrapper">
      <div className="photocard-list-page-logo-area">
        <img src="/images/bi.png" alt="bi" width={216} />
      </div>
      <div className="photocard-list-page-header">
        <span className="photocard-list-page-header-title">{`${group.name_en}`}</span>
        <div className="photocard-list-page-header-right-wrapper">
          <LocaleSelectionSimple />
          <div className="photocard-list-page-header-buttons-container">
            <div className="photocard-list-page-header-button-item-container" onClick={() => companyModal.toggle()}>
              <img src="/icons/buliding.svg" alt="help icon" className="photocard-list-page-header-button-item-icon" />
              <span className="photocard-list-page-header-button-item-text">
                <FormattedMessage id="photocard-list_company" />
              </span>
            </div>
            {companyModal.isShowing && <CompanyModal onClose={() => companyModal.close()} />}
            <div onClick={() => direct('/promotion')}>
              <div className="photocard-list-page-header-button-item-container">
                <img src="/icons/help.svg" alt="help icon" className="photocard-list-page-header-button-item-icon" />
                <span className="photocard-list-page-header-button-item-text">
                  <FormattedMessage id="photocard-list_tutorial" />
                </span>
              </div>
            </div>
            <div
              className="photocard-list-page-header-button-item-container"
              onClick={() => {
                cartData && cartData?.length > 0 ? backModal.open() : goHome();
              }}
            >
              <img src="/icons/home.svg" alt="home icon" className="photocard-list-page-header-button-item-icon" />
              <span className="photocard-list-page-header-button-item-text">
                <FormattedMessage id="photocard-list_home" />
              </span>
            </div>
          </div>
        </div>
      </div>
      {backModal.isShowing && (
        <BackModal
          onClose={() => backModal.close()}
          onClick={() => {
            resetCart();
            goHome();
          }}
        />
      )}
    </div>
  );
}
