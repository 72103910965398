import { IToggleTrigger } from '@/hooks/useToggle';
import TypeSection from '../TypeSection/TypeSection';
import SortModal from '@/components/SortModal/SortModal';
import '@/styles/photocard/list.scss';
import './FilterBar.scss';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type sortType = 'new' | 'wish';

interface IProps {
  sortModal: IToggleTrigger;
  typeSectionModal: IToggleTrigger;
}

export default function FilterBar({ sortModal, typeSectionModal }: IProps) {
  const [searchParams, _] = useSearchParams();
  const sortName = searchParams.get('sort') ?? ('new' as sortType);
  const type = searchParams.get('category')?.split(',') ?? ['all'];

  return (
    <>
      <div className="filter-bar-wrapper">
        <div className="filter-bar-total-text" />
        <div className="filter-bar-right-wrapper">
          {typeSectionModal.isShowing ? (
            <div onClick={() => typeSectionModal.close()}>
              <span>
                <FormattedMessage id={`close`} />
              </span>
              <img src="/icons/close.svg" alt="close-svg" width={23} />
            </div>
          ) : (
            <div onClick={() => typeSectionModal.open()}>
              <span>
                <FormattedMessage id={`filter`} />
              </span>
              <img src="/icons/filter.svg" alt="filter-svg" />
              {type[0] !== 'all' && type.length > 0 && <div className="filter-bar-count">{type.length}</div>}
            </div>
          )}

          <div
            className="filter-bar-Newest"
            onClick={() => {
              sortModal.toggle();
              typeSectionModal.close();
            }}
          >
            <FormattedMessage id={`sort_text_${sortName === 'new' ? 1 : 2}`} />
            <img src="/icons/sort.svg" alt="sort-svg" />
          </div>
        </div>
        {typeSectionModal.isShowing && <TypeSection />}
        {sortModal.isShowing && <SortModal onClose={() => sortModal.close()} />}
      </div>
    </>
  );
}
