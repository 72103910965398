import useAuth from './useAuth';
import { issueAccessToken, refreshAccessToken } from '@/apis/auth';
import { WebviewAction } from '@/utils/action';
import { AuthToken } from '@/types/data';

const useApi = () => {
  const { token, updateToken } = useAuth();

  const fetchApi = async <T>(fetchFn: (accessToken: string) => Promise<T>) => {
    try {
      return await fetchFn(localStorage.getItem('access') ?? '');
    } catch (error) {
      let refreshResult: AuthToken;
      if (token.refresh) {
        refreshResult = await refreshAccessToken({ params: { refresh: token.refresh } });
        updateToken({ access: refreshResult.access, refresh: token.refresh });
      } else {
        const deviceId = WebviewAction() ?? 'kioskdev';
        refreshResult = await issueAccessToken({ params: { device_id: deviceId } });
        updateToken(refreshResult);
      }
      throw error;
    }
  };

  return { fetchApi };
};

export default useApi;
