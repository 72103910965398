import useLocalStorage from '@/hooks/useLocalStorage';
import { cartDatas } from '@/types/main';
import { getPrice } from '@/utils/getPrice';
import { FormattedMessage } from 'react-intl';

export default function CartItem({ cartItemData }: { cartItemData: cartDatas }) {
  const [currency] = useLocalStorage('currency', null);
  return (
    <div key={cartItemData.photo_card.id} className="order-page-cart-item-container">
      <img src={cartItemData.photo_card.image} alt="photocard" className="order-page-cart-item-image" />
      <div className="order-page-cart-item-text-area">
        <div className="order-page-cart-item-info-text-area">
          <span className="order-page-cart-item-artist-text">{`${cartItemData.photo_card.group.name_en} ${
            cartItemData.photo_card.group.name_en === cartItemData.photo_card.member.name_en
              ? ''
              : cartItemData.photo_card.member.name_en
          }`}</span>
          <span className="order-page-cart-item-description-text">{cartItemData.photo_card.name_en}</span>
        </div>
        <div className="order-page-cart-item-price-area">
          <span className="order-page-cart-item-price-text">{getPrice(cartItemData.offer.price, currency)}</span>
          <span className="order-page-cart-item-quantity-text">
            <FormattedMessage id="order_cart_quantity" />: 1
          </span>
        </div>
      </div>
    </div>
  );
}
