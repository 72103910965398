import { updateQueryParams } from '@/routes/photocard/_lib/updateQueryParams';
import { TYPE_LIST } from '../routes/photocard/_utils/data';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const DEFAULT_FILTER = 'all';

const shouldResetToDefaultFilter = (selectedType: string, currentTypes: string[]) => {
  return TYPE_LIST.filter(({ name }) => name !== DEFAULT_FILTER && name !== selectedType).every(({ name }) =>
    currentTypes.includes(name)
  );
};

const isLastTypeBeingRemoved = (selectedType: string, currentTypes: string[]) => {
  return currentTypes.includes(selectedType) && currentTypes.length === 1;
};

const updateTypeList = (selectedType: string, currentTypes: string[]) => {
  if (currentTypes.includes(selectedType)) {
    // 선택된 타입 제거
    return currentTypes.filter((t) => t !== selectedType);
  }
  // 새 타입 추가
  return [...currentTypes.filter((t) => t !== DEFAULT_FILTER), selectedType];
};

export const useUpdateTypeSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, _] = useSearchParams();

  const updateTypeSelection = (selectedType: string, type: string[]) => {
    if (selectedType === DEFAULT_FILTER) {
      return navigate(`?${updateQueryParams(searchParams, [DEFAULT_FILTER])}`);
    }
    // 모든 타입이 선택되었는지 확인 후, 현재 선택된 타입을 제외한 모든 타입이 이미 선택되었다면 DEFAULT_FILTER만 활성화
    if (shouldResetToDefaultFilter(selectedType, type)) {
      return navigate(`?${updateQueryParams(searchParams, [DEFAULT_FILTER])}`);
    }
    // 마지막 남은 타입을 제거하려는 시도를 방지
    if (isLastTypeBeingRemoved(selectedType, type)) {
      return;
    }
    const updatedTypeList = updateTypeList(selectedType, type);
    // 모든 타입이 제거되면 DEFAULT_FILTER를 유지
    const updateType = updatedTypeList.length > 0 ? updatedTypeList : [DEFAULT_FILTER];

    navigate(`${location.pathname}?${updateQueryParams(searchParams, updateType)}`);
  };

  return { updateTypeSelection };
};
