import { FormattedMessage } from 'react-intl';
import '@/styles/index.scss';
import Button from '@/components/Button';
import LocaleSelection from '@/components/LocaleSelection/LocaleSelection';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';
import PromotionCarousel from './_components/PromotionCarousel/PromotionCarousel';

export const HomePage = () => {
  const { direct } = useWebViewRouter();

  return (
    <>
      <div className="home-container">
        <div className="home-information">
          <img src="/images/bi.png" alt="logo-img" width={216} height={26} style={{ marginBottom: '22px' }} />
          <span className="title">
            <FormattedMessage id="onboarding_title1" />
          </span>
          <span className="title">
            <FormattedMessage id="onboarding_title2" />
          </span>
          <Button className="home-onboarding-button-buy-now" onClick={() => direct('/promotion')}>
            <FormattedMessage id="onboarding_start_btn" />
          </Button>
          <LocaleSelection />
        </div>
        <PromotionCarousel />
      </div>
    </>
  );
};
