import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import { useCart } from '@/hooks';
import { getPrice } from '@/utils/getPrice';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderSendForm } from '@/types/order';
import useToggle from '@/hooks/useToggle';
import BackModal from '../BackModal';
import useApi from '@/hooks/useApi';
import { postCheckPurchase, postPurchase } from '@/apis/checkout';
import OrderErrorModal from '../OrderErrorModal';
import CheckModal from '../CheckModal';
import { useState } from 'react';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';
import { ICheckRequest } from '@/types/checkout';
import { debounce } from '@/utils/debounce';
import CartItem from './CartItem';
import CartHeader from './CartHeader';
import CartResultSection from './CartResultSection';

export const Cart = () => {
  const { direct } = useWebViewRouter();
  const { cartData } = useCart();
  const { fetchApi } = useApi();
  const [currency] = useLocalStorage('currency', null);
  const { handleSubmit, formState } = useFormContext<OrderSendForm>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMessage, setErrormessage] = useState('');

  const subtotal = cartData?.reduce((prev, cur) => (prev += Number(cur.offer.price) * 1), 0).toFixed() || '0';
  const shippingFee = 0;

  const { isDirty, isValid } = formState;
  const agreement1 = useWatch({ name: 'service_agreement_1' });
  const agreement2 = useWatch({ name: 'service_agreement_2' });
  const emailDomain = useWatch({ name: 'customer_email_2' });
  const disabled = !(isDirty && isValid) || !agreement1 || !agreement2 || !emailDomain;

  // toggle
  const orderErrorModal = useToggle({ showing: false });
  const backModal = useToggle({ showing: false });
  const checkModal = useToggle({ showing: false });

  // Submit

  const onSubmit = debounce(async (data: OrderSendForm) => {
    try {
      const cart_offers = cartData?.map((i) => i.offer.id) || [];
      await fetchApi((token) => postCheckPurchase(token, { offer_list: cart_offers, total_price: subtotal }));
      const params: ICheckRequest = {
        offer_list: cart_offers,
        payment: {
          offer_total: subtotal,
          shipping_fee: shippingFee
        },
        customer: {
          first_name: data.customer_first_name,
          last_name: data.customer_last_name,
          country_code: data.customer_country_code,
          phone_number: data.customer_phone_number,
          email: data.customer_email_1 + '@' + data.customer_email_2
        }
      };

      const realpurchase = await fetchApi((token) => postPurchase(token, params));
      direct(`/order/success/${realpurchase.shipping_request_id}`);
    } catch (e: any) {
      setIsSubmit(false);
      setErrormessage(e.message);
      orderErrorModal.open();
    }
  }, 500);

  return (
    <div className="order-page-cart">
      <CartHeader />

      <div className="order-page-cart-items-area">{cartData?.map((item) => <CartItem cartItemData={item} />)}</div>

      <CartResultSection cartDataLength={cartData?.length ?? 0} subtotal={Number(subtotal)} />

      <div className="order-page-cart-button-area">
        <Button className="order-page-cart-back-button" textColor="#000000" onClick={backModal.open}>
          <FormattedMessage id="order_cart_back" />
        </Button>
        <Button className="order-page-cart-next-button" disabled={disabled} onClick={checkModal.open}>
          <FormattedMessage id="order_cart_next" />
        </Button>
      </div>

      {checkModal.isShowing && (
        <CheckModal
          onClose={checkModal.close}
          onContinueClick={handleSubmit(onSubmit)}
          total={`${getPrice(Number(subtotal).toString(), currency)}`}
          isSubmit={isSubmit}
        />
      )}

      {backModal.isShowing && <BackModal onClose={backModal.close} onClick={() => direct('/photocard')} />}

      {orderErrorModal.isShowing && (
        <OrderErrorModal
          onAgainClick={() => {
            orderErrorModal.close();
            setErrormessage('');
          }}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};
