import { FormattedMessage } from 'react-intl';

export default function CartHeader() {
  const now = new Date().toDateString().split(' ');
  const date = {
    day: now[2],
    month: now[1],
    year: now[3]
  };

  return (
    <div className="order-page-cart-header">
      <span className="order-page-cart-title-text">
        <FormattedMessage id="order_cart_title" />
      </span>
      <div className="order-page-cart-text-wrap">
        <span className="order-page-cart-text">
          · <FormattedMessage id="order_cart_date" />: {`${date.day} ${date.month} ${date.year}`}
        </span>
      </div>
    </div>
  );
}
