import { useWebViewRouter } from '@/hooks/useWebViewRouter';
import './SkipButton.scss';
import { FormattedMessage } from 'react-intl';

const SkipButton = () => {
  const { direct } = useWebViewRouter();

  return (
    <button className="skip-button" onClick={() => direct('/photocard')}>
      <span className="skip-button-text">
        <FormattedMessage id="skip" />
      </span>
    </button>
  );
};

export default SkipButton;
