import './InfiniteCarousel.scss';

interface IProps {
  Imgs: string[];
  isReverse?: boolean;
  sectionNum: number;
  topValue: string;
}

export default function InfiniteCarousel({ Imgs, isReverse, sectionNum, topValue }: IProps) {
  return (
    <div style={{ width: '234px' }}>
      <div className="carousel" style={{ top: topValue }}>
        <div className={`${isReverse ? 'scrollingReverseAnimation' : 'scrollingAnimation'}`}>
          {Imgs.map((item) => (
            <img
              key={item}
              style={{ marginBottom: '32px' }}
              src={`/promotion/section${sectionNum}/${item}.png`}
              alt={`section${sectionNum}-${item}`}
              width={234}
              height={362}
            />
          ))}
        </div>
        <div aria-hidden className={`${isReverse ? 'scrollingReverseAnimation' : 'scrollingAnimation'}`}>
          {Imgs.map((item) => (
            <img
              key={item}
              style={{ marginBottom: '32px' }}
              src={`/promotion/section${sectionNum}/${item}.png`}
              alt={`section${sectionNum}-${item}-alt`}
              width={234}
              height={362}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
